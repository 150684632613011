import { Button, Divider, Skeleton } from '@mui/material';
import { graphql } from 'gatsby';
import * as React from 'react';
import DataService from '../../services/dataService';
import Layout from './Layout';

const Build = ({ data, pageContext }) => {

    const [updateAndBuildLog, setUpdateAndBuildLog] = React.useState(null);
    const [reset, setReset] = React.useState(false);

    React.useEffect(() => {
        fetchUpdateCount();
    }, []);

    const fetchUpdateCount = () => {

        DataService.getRecord(data.datoCmsUpdateandbuildlog.id)
            .then( buildLog => {
                setUpdateAndBuildLog(buildLog);
            })
    }

    const handleBuild = () => {
        
        const confirm = window.confirm("Are you sure you want to triger a site build?");

        if (confirm) {
            DataService.build(data.datoCmsWebsite.id).then( _ => fetchUpdateCount() ).then( _ => setReset(true));
        }
    }

    if (updateAndBuildLog == null) {
        return (
            <Layout>
                <div className="flex justify-center ml-20 mt-60">
                    <Skeleton variant='rectangular' width={300} height={300} />
                </div>
            </Layout>
        )
    }

    return (
        <Layout className="relative">
            <div className="flex justify w-3/12 m-auto" style={{ top: '40%', left: 0, right: -300, transform: 'translate(0, -50%)', position: 'absolute' }}>
                <div className="w-full bg-slate-200 p-8 rounded">
                    <p className="text-xl text-center font-semibold">Launch a new Build</p>
                    <Divider className="w-3/4 m-auto mt-4" />
                    <p className="text-slate-500 mt-4">{reset ? 0 : updateAndBuildLog?.updatecount } updates have been made since your last build</p>

                    <Button 
                        disabled={reset ? true : !updateAndBuildLog?.updatecount} 
                        variant="contained" 
                        className="w-full mt-4 bg-[#45425a] hover:bg-[#45425a] border font-mono font-bold"
                        onClick={(e) => {
                            handleBuild();
                        }}
                    >
                        Build
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export const data = graphql`
    query buildPageQuery($sitename: String) {
        datoCmsUpdateandbuildlog(website: {name: {eq: $sitename}}) {
            id
        }

        datoCmsWebsite(name: {eq: $sitename}) {
            id
        }
    }
`

export default Build;